<template>
  <div>
    <div class="router-header flex flex-wrap items-center mb-6">
      <div class="content-area__heading">
        <h2 class="mb-1">Manage</h2>
      </div>
    </div>

    <vx-card class="directors-card learning-center-card">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :max-items="dataTableParams.limit"
        :data="regionalDirectors"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        @selected="handleSelected"
        :noDataText="noDataText"
      >
        <div slot="header" class="w-full mb-8">
          <div class="flex flex-wrap items-center">
            <div class="w-full md:w-3/4">
              <div class="card-title">
                <h2>Regional Directors</h2>
              </div>
            </div>
            <div class="w-full md:w-1/4">
              <vs-button
                class="w-full md:w-auto float-right"
                color="primary"
                type="filled"
                @click="addNewRegionalDirector"
                >Add Regional Director
              </vs-button>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-10 lg:mt-0">
            <div class="w-full lg:w-3/4 flex flex-wrap header-table-row">
              <div class="w-full md:w-1/2">
                <vs-input
                  class="w-full search-input"
                  icon-pack="feather"
                  icon="icon-search"
                  v-model="searchQuery"
                  placeholder="Search..."
                />
              </div>
              <div class="w-full md:w-1/4 md:pl-10">
                <div class="select-wrapper md:mt-0">
                  <!-- <label class="block mb-2">Country</label> -->
                  <v-select
                    :options="countryOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="countryFilter"
                  >
                    <template #header>
                      <label for class="label">Country</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="w-full md:w-1/4 md:pl-10">
                <div class="select-wrapper md:mt-0">
                  <v-select
                    :options="stateOptions"
                    :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stateFilter"
                    class="mb-4 lg:mb-0"
                  >
                    <template #header>
                      <label for class="label">Region</label>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <vs-icon icon="arrow_drop_down"></vs-icon>
                      </span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="w-full lg:w-1/4">
              <div class="select-wrapper mt-0 no-border">
                <label>Sort By:</label>
                <v-select
                  :options="sortOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="sortByFilter"
                />
              </div>
            </div>
          </div>
        </div>
        <template slot="thead">
          <vs-th>Regional Director Name</vs-th>
          <vs-th>No. Assigned Centres</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            @click.stop="viewHandler(tr._id)"
          >
            <template>
              <vs-td :data="tr.name">
                {{ tr.fullName | capitalize }}
              </vs-td>
              <vs-td :data="tr.regionalDirectorLearningCenters">
                {{ tr.regionalDirectorLearningCenterCount }}
              </vs-td>
              <vs-td class="whitespace-no-wrap">
                <a
                  href="javascript::void(0)"
                  class="nav-link d-flex align-items-center active"
                  @click.stop="viewHandler(tr._id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current icon-eye"
                  />
                </a>
              </vs-td>
            </template>
          </vs-tr>
        </template>
      </vs-table>
      <div class="pagination-wrapper">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: {
    "v-select": vSelect
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        limit: 50,
        page: 1,
        country: "all",
        state: "all"
      },
      regionalDirectors: [],
      sortByFilter: { label: "Name", value: "name" },
      sortOptions: [
        { label: "Name", value: "fullName" },
        {
          label: "No. of Regional Director",
          value: "regionalDirectorLearningCenterCount"
        }
      ],
      countryOptions: [],
      countryFilter: { label: "All", value: "all" },
      stateOptions: [],
      stateFilter: { label: "All", value: "all" },
      serverResponded: false,
      searchQuery: "",
      awaitingSearch: false,
      sidebarData: {},
      noDataText: "Currently no learning center available"
    };
  },
  methods: {
    ...mapActions("center", ["getDirectorAllCountries"]),
    ...mapActions("director", ["getRegionalDirectorsListOfDirector"]),

    async getRegionalDirectorsData() {
      this.dataTableParams.country =
        this.countryFilter.value == "all" ? "" : this.countryFilter.value;
      this.dataTableParams.state =
        this.stateFilter.value == "all" ? "" : this.stateFilter.value;
      this.dataTableParams.search = this.searchQuery;
      this.dataTableParams.sort = this.sortByFilter.value;
      this.dataTableParams.directorId = this.director.id;
      this.$vs.loading();
      await this.getRegionalDirectorsListOfDirector(this.dataTableParams)
        .then(async res => {
          let directors = await res.data.data;
          this.regionalDirectors = directors.docs;
          this.totalDocs = directors.pagination.total;
          this.page = directors.pagination.page;
          this.serverResponded = true;

          if (this.regionalDirectors.length == 0) {
            this.noDataText =
              "Cannot find Regional Directors with search text: '" +
              this.searchQuery +
              "'";
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            Swal.fire({
              title: "No Access",
              html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33"
            }).then(result => {
              this.$router.push("/director/upgrade-package");
            });
          }
        });
    },

    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getRegionalDirectorsData();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getRegionalDirectorsData();
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getRegionalDirectorsData();
    },
    viewHandler(id) {
      this.$router.push({
        name: "regional-director-view",
        params: { id: id }
      });
    },
    editDetailHandler(data, id) {
      this.sidebarData = data;
      this.dataId = id;
    },
    addNewRegionalDirector() {
      this.$router.push({ name: "regional-director-add" });
    },
    handleSelected(tr) {
      this.viewHandler(tr._id);
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  watch: {
    "dataTableParams.page": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getRegionalDirectorsData();
      }
    },
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getRegionalDirectorsData();
      }
    },
    // "dataTableParams.sort": function (newSort, oldSort) {
    //   if (newSort !== oldSort) {
    //     this.dataTableParams.page = 1;
    //     this.dataTableParams.sort = newSort;
    //     this.getLearningCentreData();
    //   }
    // },
    countryFilter(obj) {
      this.stateOptions = obj.states;
      this.stateFilter = { label: "All", value: "all" };
      this.getRegionalDirectorsData();
    },
    stateFilter(obj) {
      this.getRegionalDirectorsData();
    },
    searchQuery: function(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getRegionalDirectorsData();
          this.awaitingSearch = false;
        }, 1000); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    sortByFilter(obj) {
      this.getRegionalDirectorsData();
    },
    viewByFilter(obj) {
      if (this.viewByFilter.value === "group") {
        this.$router.push({ name: "group-management" });
      }
      if (this.viewByFilter.value === "center") {
        this.$router.push({ name: "learning-centers" });
      }
    }
  },
  async created() {
    let self = this;
    this.$vs.loading();
    await this.getDirectorAllCountries()
      .then(async res => {
        this.countryOptions = await this.$store.state.center.countryOptions;
      })
      .catch(err => {
        console.error(err);
      });

    self.getRegionalDirectorsData();
  }
};
</script>

<style>
.vs-input--input.hasIcon:focus + .vs-input--placeholder {
  display: none !important;
}
</style>
